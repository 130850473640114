@import '../variables';
@import '../mixins';

$action-blue: cssvar(brand-base);

.toolbox {
    width: 230px;
    border-right: 1px dashed cssvar(border-and-dividers);
    background: cssvar(cards-and-blocks);
    user-select: none;
    &-new {
        margin-top: 95px;
        user-select: none;
    }
}

.workflow-builder-view {
    margin-top: 95px;
}

.drop-area {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    font-size: 14px;
    color: $gray-3;
    &-new {
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        font-size: 14px;
        color: cssvar(title);
    }
    &-dotted-bg {
        background-image: radial-gradient(cssvar(disable) 10%, transparent 10%);
        background-size: 13px 13px;
        background-color: cssvar(blackout);
    }
    &-background {
        background: rgba(0, 0, 0, 0.04);
    }
}

.area-centered {
    top: 56px;
    right: 56px;
    bottom: 56px;
    left: 56px;
    border: 1px dashed cssvar(border-and-dividers) !important;
    &-new {
        left: 28px;
        right: 23px;
        bottom: 60px;
        border: 1px dashed cssvar(brand-base) !important;
        border-radius: 4px;
    }
}

.actions-panel {
    position: absolute;
    top: 20px;
    right: 30px;
    display: flex;
    align-items: center;
    z-index: 1;
    -webkit-backface-visibility: hidden;

    .user-select-none {
        user-select: none !important;
    }

    .actions-group {
        background: cssvar(cards-and-blocks);
        line-height: 25px;
        border: 1px solid $action-blue;
        border-radius: 21px;
        cursor: pointer;

        .border-x {
            border-left: 1px solid $action-blue;
            border-right: 1px solid $action-blue;
        }

        .border-left-none {
            border-left: none !important;
        }

        .action {
            width: 14px;
            height: 25px;
            cursor: pointer;
            color: $action-blue;

            &--undo {
                transform: scaleX(-1);
            }

            &--disabled {
                color: cssvar(disabled);
                cursor: default;
            }
        }
    }

    .action-blue {
        color: $action-blue;
    }

    .open-version-icon {
        * {
            fill: cssvar(brand-base);
        }
    }
}

::ng-deep {
    .dragging--post-action .post-action-drop-zone {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
    }
}

@media (max-width: 900px) {
    .actions-panel {
        left: 255px;
    }
}
