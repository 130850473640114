// Components import
@import 'components/graph.component.scss';
@import 'components/dashboard.component.scss';
@import 'components/ckeditor.component.scss';
@import 'components/builder.component.scss';

@import './variables';
@import './mixins';

.text-muted,
.app-notification__meta {
    color: cssvar(primary-text) !important;
}

.dynamic-cards {
    margin-bottom: 5px;
    margin-right: 5px;
}

.top-colored-tab {
    .nav-tabs {
        .nav-link {
            border-radius: 0;
            border-bottom: 1px solid cssvar(border-and-dividers);
            font-weight: bold;
            color: cssvar(secondary-text-and-icons);

            &:hover,
            &.active {
                border-top: 1px solid cssvar(brand-base);
                color: cssvar(brand-base);
                &:focus {
                    border-color: cssvar(border-and-dividers) !important;
                    border-top: 1px solid cssvar(brand-base) !important;
                }
            }
        }
    }
}

.main-container-projects {
    height: calc(100vh - #{$default-header-height});
    overflow-x: hidden !important;
    overflow-y: auto !important;

    .breadcrumb {
        display: none;
    }
}

/* Error pages */
.bootstrap-error {
    .info1 {
        color: cssvar(secondary-text-and-icons);
    }
    .title2 {
        color: cssvar(grid-no-rows-layout-data) !important;
    }
    .btn-primary {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.3334 2.66667V6.66667M15.3334 6.66667H11.3334M15.3334 6.66667L12.2401 3.76C11.5236 3.04314 10.6372 2.51947 9.66354 2.23785C8.68992 1.95623 7.66082 1.92584 6.67227 2.14951C5.68372 2.37318 4.76795 2.84362 4.01039 3.51695C3.25284 4.19027 2.67819 5.04453 2.34008 6M0.666748 13.3333V9.33333M0.666748 9.33333H4.66675M0.666748 9.33333L3.76008 12.24C4.47658 12.9569 5.363 13.4805 6.33662 13.7622C7.31024 14.0438 8.33934 14.0742 9.32789 13.8505C10.3164 13.6268 11.2322 13.1564 11.9898 12.4831C12.7473 11.8097 13.322 10.9555 13.6601 10' stroke='%23FBFBFB' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
        padding-left: 32px;
        background-position: 16px center !important;
        background-repeat: no-repeat !important;
        width: 110px;
    }
    .btn-secondary {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6M10.6667 11.3333L14 8M14 8L10.6667 4.66667M14 8H6' stroke='%230097AB' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
        padding-left: 32px;
        background-position: 16px center !important;
        background-repeat: no-repeat !important;
        margin-left: 24px;
        width: 110px;
    }
    .not-found {
        background: url('/assets/images/500.png') right center no-repeat;
        background-size: 50%;
        height: 85vh;
    }
}

.error-page-template {
    width: 512px;
    margin: 0 auto;

    .server-error,
    .not-found,
    .access-denied {
        display: block;
        width: 100%;
        height: 250px;
    }

    .server-error {
        background: url('/assets/images/server-error.png') center center no-repeat;
        background-size: 50%;
    }

    .not-found {
        background: url('/assets/images/404.png') center center no-repeat;
        background-size: 50%;
    }

    .access-denied {
        background: url('/assets/images/access-denied.jpg') center center no-repeat;
        background-size: 40%;
    }

    p {
        font-size: 16px;
        font-weight: bold;
        color: cssvar(secondary-text-and-icons);
        text-align: center;
    }
}

/* Min width classes */

.min-width-80px {
    min-width: 80px;
}

/* TSM page */
.tsm-template {
    border: 0;
    background: transparent;

    .card-body {
        padding-left: 0;
        padding-right: 0;
    }
}

/* Other customizations */
.positioned-over-the-table {
    bottom: -42px;
}

.full-width-wrapper {
    margin-left: -30px;
    margin-top: -15px;
    margin-right: -30px;
}

.widgets-empty {
    padding: 80px 0;
}

.extend-horizontal-padding {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.compress-vertical-padding {
    padding-top: 0.15em;
    padding-bottom: 0.15em;
}

.extend-horizontal-padding,
.compress-vertical-padding {
    font-size: 12px;
}

.scroll-wrapper {
    height: 510px;
    overflow: auto;
}

rect.highcharts-button-box {
    fill: $white !important;
}

/* Drag and Drop CSS */
.display-list {
    width: 500px;
    max-width: 100%;
    min-height: 60px;
    display: block;
    background: white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.drag-list {
    max-height: 380px;
    list-style-type: none;
    padding-left: 0;
    overflow-y: auto;

    li {
        display: flex;
        border: 0.75px solid cssvar(border-and-dividers);
        color: cssvar(primary-text);
        border-radius: 5px;
        padding: 5px;
        margin: 0.25rem 0;
        height: 40px;
        cursor: move;
        overflow: visible;

        .drag-thumbnail {
            width: 15%;

            img {
                width: 65%;
                color: #f2f2f2;
                background: #f2f2f2;
            }
        }

        .drag-details {
            flex-basis: 75%;
            line-height: 2.5;
        }

        .drag-options {
            flex-basis: 25%;

            .btn {
                display: none;
            }
        }

        .drag-handle {
            width: 15px;
            line-height: 2;
            color: #b3b3b3;
        }

        &:hover {
            background: cssvar(brand-12);
            border: 1px solid cssvar(brand-base);
            box-shadow: cssvar(card-hover-box-shadow);

            .drag-options {
                .btn {
                    display: block;
                }
            }
        }
    }
}

.cdk-drag-placeholder {
    opacity: 0.9;
    display: flex;
    background: #ffffff;
    border: 1px solid cssvar(brand-base);
    box-shadow: 0 0 8px 2px cssvar(brand-base);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 5px;
    height: 40px;

    .drag-thumbnail {
        width: 15%;

        img {
            width: 65%;
            color: #f2f2f2;
            background: #f2f2f2;
        }
    }

    .drag-details {
        flex-basis: 75%;
        line-height: 2.5;
    }

    .drag-options {
        flex-basis: 25%;

        .btn {
            display: none;
        }
    }

    .drag-handle {
        width: 15px;
        line-height: 2;
        color: #b3b3b3;
    }
}

/* Animate items as they're being sorted. */
.drag-list.cdk-drop-list-dragging li:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
    display: none;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.error-info-block {
    padding-top: 8.5rem;
    padding-bottom: 7rem;

    .error-info-message {
        font-size: 18px;
        padding-top: 0.25rem;
    }
}

.CodeMirror-lint-tooltip {
    z-index: 1060;
}

.notification-placeholder-right-panel {
    .modal-dialog {
        width: 300px;
        padding: 0;
        margin: 0 0 0 auto;
        height: 100%;

        .modal-content {
            border: none;
            border-radius: 0;
            height: 100%;
        }
    }
}

.edit-job-definition-modal {
    .modal-dialog {
        min-width: 1024px;
    }

    .modal-content {
        overflow: inherit;
    }
}

button:not(.btn):focus {
    outline: none;
}

.card-reset {
    .card {
        margin-bottom: 10px;
        box-shadow: 0 0 4px -3px #000;

        &:first-of-type,
        &:not(:first-of-type):not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }

        &:last-of-type,
        &:first-of-type,
        &:not(:first-of-type):not(:last-of-type) {
            border-radius: 8px;
        }

        .card-header {
            background: transparent;
            border-bottom: 0;
            padding: 8px 20px;
        }
    }

    .inner-card-reset {
        > .card {
            box-shadow: none;
            border: 0 !important;
            margin-bottom: 0;

            .card-header {
                padding: 0;
            }

            .card-body {
                padding: 0 1.25rem;
            }
        }

        .accordion-panel-header {
            padding-left: 20px;
        }
    }
}

.project-build-details {
    .card {
        border: 0;

        .card-header {
            background: none;
        }
    }

    .inner-card-reset {
        &.accordion {
            .card {
                border-bottom: 0 !important;

                .card-header {
                    padding: 0.75rem 1.25rem;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                }

                .card-body {
                    padding-top: 0;

                    .component-pipeline th {
                        padding: 0 10px !important;
                    }
                }
            }
        }
    }
}

.success-color {
    color: $color-4-hover !important;
}

.failed-color {
    color: $color-0-normal !important;
}

.context-help-stage {
    position: relative;
    z-index: 1033;

    &:not(.btn) {
        background: #fff;
    }
}

.relation-button {
    &.follower {
        min-width: 63px;
    }

    &.contributor {
        min-width: 86px;
    }
}

.not-found-text {
    font-size: 14px;
    color: cssvar(brand-base);
    margin: -25px 0 30px;
}

.not-found-image {
    width: 185px;
    height: 150px;
    background: url('/assets/images/not-found.svg') center center no-repeat;
    background-size: 50%;
}

.counter-badge {
    font-size: 10px;
    width: 30px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
}

.warning-text {
    color: cssvar(warning-base);
}

.text-highlight {
    background-color: cssvar(brand-base);
    color: cssvar(body-bg);
}

.count-notification {
    vertical-align: middle;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    background: $color-1-normal;
    color: #ffffff;
    margin-left: 5px;
    padding: 1px 4px;
    font-size: 8px;
}

.wrapper-max-height-100vh-50 {
    max-height: calc(100vh - 50px);
}

.wrapper-max-height-100vh-61 {
    max-height: calc(100vh - 61px);
}

.wrapper-max-height-100vh-75 {
    max-height: calc(100vh - 75px);
}

.wrapper-max-height-100vh-110 {
    max-height: calc(100vh - 110px);
}

.wrapper-max-height-100vh-150 {
    max-height: calc(100vh - 150px);
}

.wrapper-max-height-100vh-180 {
    max-height: calc(100vh - 180px);
}

.wrapper-max-height-100vh-210 {
    max-height: calc(100vh - 210px);
}

.wrapper-max-height-100vh-250 {
    max-height: calc(100vh - 250px);
}

.wrapper-max-height-100vh-310 {
    max-height: calc(100vh - 310px);
}

.wrapper-max-height-100vh-320 {
    max-height: calc(100vh - 320px);
}

.wrapper-max-height-100vh-350 {
    max-height: calc(100vh - 350px);
}

.wrapper-max-height-100vh-380 {
    max-height: calc(100vh - 380px);
}

.wrapper-max-height-100vh-385 {
    max-height: calc(100vh - 385px);
}

.wrapper-max-height-100vh-420 {
    max-height: calc(100vh - 420px);
}

.wrapper-max-height-100vh-450 {
    max-height: calc(100vh - 450px);
}

// EO-7730

@media (min-width: 1600px) {
    .font-12px {
        font-size: 11px;
    }
    .font-18px {
        font-size: 16px;
    }
}

span.ag-header-icon {
    &.ag-header-cell-menu-button {
        transition: none !important;
    }
}

.CodeMirror-hint-active,
.hint-active .completion-details {
    display: block;
}

.sortable {
    cursor: pointer;
}

.sortable::after {
    margin-left: 5px;
    content: '\f107';
    font: normal normal normal 16px/1 'FontAwesome', sans-serif;
}

.sort-desc-active::after {
    margin-left: 5px;
    content: '\f106';
    font: normal normal normal 16px/1 'FontAwesome', sans-serif;
    color: cssvar(brand-base);
}

.sort-asc-active::after {
    margin-left: 5px;
    content: '\f107';
    font: normal normal normal 16px/1 'FontAwesome', sans-serif;
    color: cssvar(brand-base);
}

.thumbnail {
    min-width: 22px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    background: cssvar(brand-base);
    color: cssvar(cards-and-blocks);
    margin-top: auto;
    margin-bottom: auto;

    img {
        max-width: 9px;
    }
}

.control-container {
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    margin-left: auto;

    .search {
        width: 300px;
        max-width: 88vw;
    }
}

@media (max-width: 660px) {
    .control-container {
        margin-left: 0;
        justify-content: flex-start !important;
    }
}

.text-secondary {
    color: cssvar(secondary-text-and-icons) !important;
}

$collapsed-sidenav: 48px;
.wrapper-max-width-with-collapsed-sidenav {
    max-width: calc(100vw - #{$collapsed-sidenav});
}

@keyframes opacity-animation {
    0% {
        display: none;
        opacity: 0;
    }

    10% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
