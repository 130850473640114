.widget-list-column {
    list-style-type: none;

    .widget-thumbnail {
        float: left;

        img {
            max-width: 90px;
        }
    }
}

.widget-modal {
    .modal-dialog {
        max-width: 1000px;
    }

    @media (min-width: 1200px) {
        .modal-dialog {
            max-width: 1125px;
        }
    }

    .chart-customization {
        .add-widget {
            .ng-select-container {
                min-width: 200px;
                height: 32px;
                min-height: 32px;
            }

            .widget-list {
                padding: 15px 0 30px 0;
                max-height: 438px;
                min-height: 438px;
                list-style-type: none;

                li {
                    display: flex;
                    border-bottom: 1px solid #efefef;
                    padding: 15px;
                    border-collapse: collapse;

                    &:last-child {
                        border-bottom: 0;
                    }

                    .widget-thumbnail {
                        width: 150px;

                        img {
                            max-width: 100%;
                            border: 1px solid #dfe2e5;
                            padding: 3px;
                        }
                    }

                    .widget-details {
                        flex-basis: 75%;
                    }

                    .widget-options {
                        flex-basis: 25%;

                        .btn {
                            display: none;
                        }
                    }

                    &:hover {
                        box-shadow: 0 0 11px -2px #dfdfdf;

                        .widget-options {
                            .btn {
                                display: block;
                            }
                        }
                    }
                }
            }

            .chart-customization-left-menu {
                .card {
                    border: 0;

                    .card-header {
                        background: transparent;
                        padding: 0;
                        border: 0;

                        .btn-link {
                            width: 100%;
                            text-align: left;
                            padding: 7px 0;
                            color: #555;
                            font-size: 12px;
                            position: relative;
                            &:hover,
                            &:active,
                            &:focus {
                                text-decoration: none;
                            }

                            &:after {
                                position: absolute;
                                right: 0;
                                top: 8px;
                                content: '\f107';
                                font: normal normal normal 16px/1 'FontAwesome', sans-serif;
                            }

                            &.collapsed {
                                &:after {
                                    content: '\f105';
                                }
                            }
                        }
                    }

                    .card-body {
                        padding: 0;

                        ul {
                            list-style-type: none;

                            li {
                                a {
                                    color: #555;
                                    padding: 7px 15px;
                                    display: block;
                                    border: 1px solid transparent;
                                    border-left-width: 2px;
                                }

                                &.active,
                                &:hover {
                                    a {
                                        background: #f8f8f8;
                                        border: 1px solid #dfe2e5;
                                        border-left: 2px solid #317ce2;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .customize-widget {
            .accordion {
                .card {
                    &:first-of-type {
                        margin-top: 20px;
                    }

                    border: 0;

                    .card-header {
                        padding: 0.1rem 0.25rem;
                        background-color: #fff;
                        border-bottom: 1px solid #dfe2e5;
                        margin-bottom: 0;

                        .btn-link {
                            color: #666;
                            font-size: 12px;
                            width: 100%;
                            text-align: left;
                            padding: 0.375rem 0.25rem;
                            position: relative;

                            &:hover,
                            &:focus {
                                text-decoration: none;
                            }

                            &::after {
                                content: '\f107';
                                font-family: 'fontAwesome', sans-serif;
                                position: absolute;
                                top: 2px;
                                right: 10px;
                                font-size: 16px;
                            }

                            &.collapsed {
                                &::after {
                                    content: '\f105';
                                }
                            }
                        }
                    }
                }

                .colors {
                    .ng-star-inserted {
                        float: left;
                        margin: 0 10px 10px 0;

                        > div {
                            width: 25px;
                            height: 25px;
                            border-radius: 3px;
                            text-indent: -999em;
                            border: 1px solid $gray-6;
                        }
                    }
                }
            }

            .customiztion-options-btns {
                bottom: 20px;
                right: 35px;
            }

            .color-picker {
                top: 310px !important;

                .button-area {
                    padding-bottom: 40px;

                    button.ng-star-inserted {
                        &::before {
                            content: 'Add';
                        }

                        float: right;
                        width: 54px;
                        height: 25px;
                        display: block;
                    }
                }
            }
        }
        &.overlay-added::after {
            position: absolute;
            content: '';
            background: rgba(0, 0, 0, 0.5);
            top: 0;
            right: 0;
            bottom: -1px;
            left: -1px;
            z-index: 2;
        }
    }
}

// EO-7730

@media (min-width: 1600px) {
    .widget-modal {
        .chart-customization {
            .customize-widget {
                .accordion .card {
                    .card-header {
                        .btn-link {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
}
