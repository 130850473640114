.cke_top {
    background: cssvar(background) !important;
    color: cssvar(primary-text) !important;
}

.cke_button_label {
    color: cssvar(secondary-text-and-icons) !important;
}

.cke_combo_button,
.cke_combo_button:hover {
    background: none !important;
    border-color: cssvar(brand-base) !important;
    margin-right: 3px !important;
    margin-left: 3px !important;
    padding: 0 !important;
    cursor: pointer;
}

.cke_button_on {
    background: none !important;
    border-color: cssvar(brand-base) !important;
}

.cke_combo_button:hover {
    margin: -1px 2px !important;
}

.cke_combo_text {
    color: cssvar(secondary-text-and-icons) !important;
    cursor: pointer !important;
}

.cke_combo_arrow {
    border-top-color: cssvar(secondary-text-and-icons) !important;
}

.cke_button:default {
    cursor: pointer !important;
    background: none !important;
    border-color: cssvar(border-and-dividers) !important;
}

.cke_button:hover {
    cursor: pointer !important;
    background: none !important;
    border-color: cssvar(brand-base) !important;
}

.cke_bottom {
    border-top-color: cssvar(border-and-dividers) !important;
    background: cssvar(background) !important;
}

textarea.cke_source {
    background: cssvar(background) !important;
    color: cssvar(primary-text) !important;
}

.cke_dialog_body,
.cke_dialog_title {
    background: cssvar(cards-and-blocks) !important;
    color: cssvar(primary-text) !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 600 !important;

    .cke_dialog_close_button {
        background: none !important;
        top: 17px;
        right: 20px !important;

        &::before {
            content: '\00d7';
            font: normal 100 normal 24px 'FontAwesome', sans-serif;
            color: cssvar(secondary-text-and-icons);
        }
    }
}

.cke_reset_all,
.cke_reset_all * {
    color: cssvar(primary-text) !important;
}

.cke_dialog_ui {
    &_vbox_child,
    &_html {
        color: cssvar(primary-text) !important;
    }
}

.cke_dialog_title {
    padding: 20px 24px !important;
    border-bottom-color: cssvar(border-and-dividers) !important;
    font-size: 17px !important;
}

.cke_dialog_footer {
    padding: 10px !important;
    background: cssvar(table-header) !important;
    border-top-color: cssvar(border-and-dividers) !important;

    .cke_resizer_ltr {
        margin-right: 0 !important;
        margin-top: 26px !important;
        border-color: transparent cssvar(secondary-text-and-icons) transparent transparent !important;
    }

    .cke_dialog_footer_buttons {
        td {
            padding-right: 10px !important;

            &.cke_dialog_ui_hbox_first {
                padding-right: 20px !important;
            }
        }
    }
}

.cke_dialog_tab {
    background: cssvar(cards-and-blocks) !important;
    color: cssvar(primary-text) !important;
    border: none !important;
    min-width: auto !important;
    margin: 0 10px !important;

    &.cke_dialog_tab_selected {
        border-bottom: 1px solid cssvar(brand-base) !important;
        color: cssvar(brand-base) !important;
    }

    &:focus {
        padding: 4px 8px !important;
    }
}

.cke_dialog_ui_input_textarea,
.cke_dialog_ui_input_tel {
    border-color: cssvar(border-and-dividers) !important;
    background-color: cssvar(cards-and-blocks) !important;
}

.cke_dialog_contents {
    border-color: cssvar(border-and-dividers) !important;
    background-color: cssvar(cards-and-blocks) !important;

    .cke_dialog_contents_body {
        padding: 24px;

        .cke_dialog_ui_labeled_label {
            color: cssvar(title);
            display: flex;
            margin-bottom: 8px;
        }

        .cke_dialog_ui_input_select,
        .cke_dialog_ui_input_text {
            background-color: cssvar(cards-and-blocks);
            border-color: cssvar(border-and-dividers);
            border-radius: 4px;
            color: cssvar(primary-text);
            margin-bottom: 5px;

            &:focus {
                border: 1px solid cssvar(brand-base);
            }

            option {
                color: cssvar(primary-text);
                background-color: cssvar(cards-and-blocks);
            }
        }

        .cke_dialog_ui_checkbox {
            label {
                color: cssvar(primary-text);
            }
        }

        .cke_dialog_ui_fieldset {
            border: none;

            legend,
            label {
                color: cssvar(primary-text);
            }
        }
    }
}

.cke_dialog_ui_button {
    border-radius: 4px !important;
    border-color: cssvar(brand-base) !important;
    color: cssvar(brand-base) !important;
    background: none !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: normal !important;

    &.cke_dialog_ui_button_ok {
        background: cssvar(brand-base) !important;

        .cke_dialog_ui_button {
            color: #ffffff !important;
        }
    }
}

.cke_panel_grouptitle {
    background-color: #0b2e13 !important;
}

.cke_panel_frame {
    background-color: cssvar(background) !important;
}
