@import '../variables';
@import '../mixins';

@keyframes graphLoader {
    0% {
        background-position: -450px 0;
    }

    100% {
        background-position: 450px 0;
    }
}

.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: graphLoader;
    animation-timing-function: ease-in;
    background: #f6f6f6;
    background: linear-gradient(to right, #e9ecef 8%, #fefefe 18%, #e9ecef 33%);
    background-size: 1000px 50px;
    position: relative;
}

.template-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .charttoggle,
    .normalchart,
    .multi-chart,
    .drilldown-chart,
    .custom-chart {
        box-sizing: border-box;
        width: 100%;

        .insights-card {
            margin: 0 5px 10px 5px;

            > .card-body {
                max-height: 525px;
                min-height: 525px;
                box-shadow: 0 3px 6px -6px rgba(0, 0, 0, 0.4);
                padding: 0;

                .card-body {
                    padding: 15px;

                    .error-info-block {
                        .tooltip.show {
                            top: -90px !important;
                        }
                    }
                }
            }

            .ng-dropdown-panel {
                width: 215px !important;
            }
        }
        ::ng-deep .status-tooltip {
            ::ng-deep .tooltip-inner {
                text-align: center;
            }
        }
        ::ng-deep .calculation-tooltip {
            ::ng-deep .tooltip-inner {
                width: 400px;
                background-color: rgb(0, 0, 0);
            }
        }
        .drilldown-indicator {
            position: absolute;
            display: block;
            top: 14px;
            right: 56px;
            border-radius: 80%;
            width: 16px;
            height: 16px;
            color: cssvar(cards-and-blocks);
            text-align: center;
            background-color: cssvar(brand-base);
            .status-count {
                top: 2px;
                left: 6px;
                font-size: 9px;
                position: absolute;
            }
        }
        .info-handle,
        .zoom-handle,
        .edit-handle,
        .widget-handle {
            top: 10px;
            right: 50px;
            height: 30px;
            display: block;
            padding: 0 15px;
            font: normal normal normal 14px/28px 'FontAwesome', sans-serif;
            cursor: pointer;
            color: cssvar(secondary-text-and-icons);

            &::before {
                content: '\f065';
            }
        }

        .info-handle {
            right: 25px;

            &::before {
                content: '\f1ec';
            }
        }
        .edit-handle {
            right: 25px;

            &::before {
                content: '\f1f8';
            }
        }
        .widget-handle {
            right: 25px;

            &::before {
                content: '\f044';
            }
        }
        &.gantt-chart,
        &.chart-zoom-enabled-4x4,
        &.chart-zoom-enabled {
            $side-menu-width: 200px;
            $paddings: 2 * 24px;

            min-width: 100%;
            max-width: calc(100vw - #{$side-menu-width + $paddings});
            transition: all 0.5s;

            .insights-card {
                .card-body {
                    max-height: none;
                    min-height: 0;

                    .error-info-block {
                        .tooltip.show {
                            top: -90px !important;
                        }
                    }

                    .tile-rating-box {
                        border-radius: 5px 0 0 5px;
                        box-shadow: 0 8px 10px rgba(0, 0, 0, 0.03);
                        width: 55px !important;
                        height: 4.5rem;

                        .tile-rating {
                            font-style: normal;
                            font-weight: normal;
                            height: 4.5rem;
                            font-size: 18px;
                            display: flex;
                            align-items: center;
                            color: cssvar(cards-and-blocks);
                            margin: 0;
                            padding: 0 1.25rem;
                            cursor: default;
                        }
                    }
                }
            }
        }

        .custom-legend-table-wrap {
            max-height: 25vh;
        }

        .box-table-scroll-wrap {
            max-height: 25vh;
            @media (min-width: 1600px) {
                max-height: 22vh;
            }
        }

        // Behaviour changes on 4x4 zoom
        &.chart-zoom-enabled-4x4 {
            .title {
                font-size: 14px;
            }
            .subtitle {
                font-size: 12px;
            }
            .zoom-handle {
                &::before {
                    content: '\f066';
                }
            }
            .chart,
            .custom-chart-size {
                height: 824px;
            }
            .custom-chart-font {
                font-size: 18px;
            }
            .custom-chart-size {
                &.height-adjusted-progress-chart,
                &.progress-chart,
                &.tiles-group {
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                }
                &.progress-chart {
                    > .pt-30px {
                        &:first-child {
                            padding-top: 0;
                        }
                    }
                }
                &.height-adjusted-progress-chart {
                    .progress-bars {
                        width: 100%;
                        font-size: 18px;
                        .one,
                        .two,
                        .three,
                        .nodata {
                            line-height: 25px;
                            height: 25px;
                        }
                    }
                }

                &.align-items-center-on-full-zoom {
                    .graph-card-back {
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .table-text-wrap {
                display: flex;
                align-items: center;
                .table-text {
                    table {
                        margin-top: 0;
                        tbody {
                            max-height: 725px;
                        }
                    }
                }
            }

            .multi-content {
                display: flex;
                align-items: center;
            }

            .custom-legend-table-wrap,
            .box-table-scroll-wrap {
                max-height: 90vh;
            }
        }

        &.gantt-chart {
            .zoom-handle {
                display: none;
            }
        }
    }

    // Gauge chart
    .gauge-chart {
        width: 100%;

        .reading-block {
            font-size: 30px !important;
            line-height: 175px !important;
        }

        .reading-label {
            font-size: 20px !important;
            line-height: 230px !important;
        }

        .ngx-gauge-meter {
            top: 60px !important;
            margin: 0 auto;
            display: block;
        }

        .guage-chart-list {
            list-style-type: none;
            margin: 0 auto;
            max-width: 245px;
            text-align: center;
            padding: 0;
            position: relative;
            top: -35px;

            li {
                display: inline-block;
                padding: 0 5px;
                margin-bottom: 10px;
                line-height: 1;
                color: #ffa1b5;
                border-right: 1px solid #86c7f3;

                &:last-child {
                    border-right: 0;
                }
            }
        }
    }

    // Graph card styles


    // Insights title styles for graphs
    .insights-titles {
        top: 5px;
        left: 0;
        right: 0;

        .insights-title {
            font-size: 16px;
            color: cssvar(primary-text);
        }

        .insights-subtitle {
            font-size: 12px;
            padding-top: 7px;
            color: cssvar(secondary-text-and-icons);
        }
    }

    // Table text chart
    .table-text {
        .status-table {
            th {
                font-size: 1em;
                color: cssvar(title);
                background: cssvar(brand-20);
            }

            td {
                color: cssvar(title);
                border-bottom: 1px solid cssvar(border-and-dividers);
            }

            td,
            th {
                padding: 10px 8px;
            }
        }
    }

    .card-body {
        .app-table-text:not(:first-child) {
            .table-text {
                padding-top: 0;
            }
        }
    }

    // Progress chart component styles
    .progress-chart {
        .one,
        .two,
        .three {
            height: 35px;
            text-align: center;
            line-height: 35px;
        }

        .nodata {
            height: 35px;
            text-align: center;
            line-height: 35px;
            background: rgb(175, 175, 175);
            flex-basis: 100%;
        }

        .one,
        .legend-one {
            background: #2dc587;
        }

        .two,
        .legend-two {
            background: #385def;
        }

        .three,
        .legend-three {
            background: #44a6c6;
        }

        .legend-one,
        .legend-two,
        .legend-three {
            width: 12px;
            height: 12px;
            display: inline-block;
            border-radius: 50%;
        }

        .issue {
            text-align: center;
            line-height: 35px;

            .legend {
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }

            .to-do {
                background: #385def;
            }

            .in-progress {
                background: #44a6c6;
            }

            .done {
                background: #2dc587;
            }
        }

        .progress-chart-legends {
            font-weight: 700;
            color: cssvar(primary-text);
        }

        @media screen and (max-width: 1280px) {
            .font-12px {
                font-size: 11px !important;
            }

            .font-14px {
                font-size: 12px !important;
            }
        }

        @media screen and (max-width: 1024px) {
            .font-14px {
                font-size: 11px !important;
            }
        }
    }

    // ChartToggle
    .charttoggle {
        .ng-select-container {
            width: 100%;
        }

        .zoom-handle,
        .edit-handle,
        .widget-handle {
            z-index: 2;
            top: 15px;
            padding: 0 0 0 20px;
        }
    }

    .small-dropdowns {
        .ng-select {
            .ng-select-container {
                height: 26px;
                padding: 0 15px;
                min-height: 26px;
            }
        }
    }

    &.widgets {
        padding-top: 40px;
    }

    &.composites {
        padding-top: 255px;

        &.app-health-compact {
            padding-top: 120px;
        }
    }
}

.graph-card-front {
    .wrapper {
        margin: 0 auto;
        padding: 80px 0;
        display: flex;

        .wrapper-cell {
            display: flex;
            margin-bottom: 30px;
            width: 100%;

            .image-placeholder {
                height: 90px;
                width: 25%;
                @extend .animated-background;
            }

            .text-placeholder {
                margin-left: 20px;
                width: 75%;

                .text-line {
                    color: cssvar(secondary-text-and-icons);
                    height: 5px;
                    margin: 14px 0;
                    @extend .animated-background;
                }
            }
        }
    }
}

.plain-card-head {
    background: cssvar(cards-and-blocks);
    height: 60px;
}
.tab-like-border {
    > div {
        border-bottom: 1px solid cssvar(border-and-dividers);
        > div {
            border-right: 1px solid cssvar(border-and-dividers);
        }
    }
    > div:last-child {
        > div {
            border-right: 0;
        }
    }
}
.tiled-report {
    border: 1px solid cssvar(border-and-dividers);
    padding: 10px;
    border-radius: 10px;
    margin-right: 2%;
    max-width: 20%;
}
.tile-colors {
    .open {
        background: cssvar(brand-base);
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
    }

    .closed {
        background: #26ae60;
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
    }

    .total {
        background: #747474;
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
    }
}
.middle {
    width: 88%;
    overflow: hidden;
    white-space: nowrap;
}
.right-0 {
    right: 0;
}
.left-25 {
    left: 25px;
}
.top-10 {
    top: 10px;
}
.progress-bars {
    div:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    div:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .one,
    .two,
    .three,
    .nodata {
        height: 15px;
        text-align: center;
        line-height: 15px;
        color: cssvar(cards-and-blocks);
    }

    .nodata {
        background: rgb(175, 175, 175);
        flex-basis: 100%;
    }

    .one,
    .legend-one {
        background: #4fad54;
    }

    .two,
    .legend-two {
        background: #ff9800;
    }

    .three,
    .legend-three {
        background: #1eaaf2;
    }

    .legend-one,
    .legend-two,
    .legend-three {
        width: 12px;
        height: 12px;
        display: inline-block;
    }

    .progress-chart-legends {
        font-weight: 700;
        color: cssvar(primary-text);
    }

    @media screen and (max-width: 1280px) {
        .font-12px {
            font-size: 11px !important;
        }

        .font-14px {
            font-size: 12px !important;
        }
    }

    @media screen and (max-width: 1024px) {
        .font-14px {
            font-size: 11px !important;
        }
    }

    .progress-bar-legends {
        font-weight: 700;
        color: cssvar(primary-text);
    }
}

.height-adjusted-progress-chart {
    margin-top: 145px;
}
.chart-zoom-enabled {
    .height-adjusted-progress-chart {
        margin-top: 15px;
    }
}

.template-grid.customgrid {
    .multi-chart,
    .charttoggle {
        min-width: 100%;
        max-width: 100%;
    }
}

// Temporary fix
.highcharts-credits {
    display: none !important;
}

.temp-popup {
    left: 87px;
    top: -121px;
    display: none;
    background: cssvar(cards-and-blocks);
    width: 285px;
    border: 1px solid #ececec;
    box-shadow: 0 4px 17px -14px #000;

    > span {
        color: #ff5d5e;
    }

    .status-indication {
        padding: 8px;
        background: #f3f3f1;
        border-radius: 50%;
        color: #ff5d5e;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-right-color: cssvar(cards-and-blocks);
        border-left: 0;
        margin-top: -12px;
        margin-left: -12px;
    }
}

.temp-click-catcher {
    left: 37%;
    top: 46%;
    width: 8%;
    height: 15%;
    display: block;

    &:hover {
        .temp-popup {
            display: block;
        }
    }
}

/* Drilldown component */
table {
    &.main-table {
        border-collapse: separate;

        tr {
            &.main-table-head {
                .toggle-hide {
                    color: cssvar(primary-text);
                }

                .toggle-show {
                    color: cssvar(brand-base);
                }

                th {
                    background: cssvar(cards-and-blocks);
                    border: 1px solid cssvar(border-and-dividers);
                    border-radius: 5px;

                    &:after {
                        content: '\f105';
                        position: absolute;
                        top: 5px;
                        right: 20px;
                        font-size: 16px;
                        color: cssvar(cards-and-blocks);
                        font-family: 'fontAwesome', sans-serif;
                    }
                }

                &.opened {
                    th {
                        border: 1px solid cssvar(border-and-dividers);

                        &:after {
                            content: '\f107';
                        }
                    }
                }
            }

            &.table-contents {
                td {
                    padding: 0;
                    border: 0;

                    table {
                        border-collapse: inherit;
                        border-spacing: 0;
                        border: 1px solid cssvar(border-and-dividers);
                        border-radius: 5px !important;

                        th {
                            background: cssvar(table-header);

                            &:first-child {
                                border-top-left-radius: 5px !important;
                            }

                            &:last-child {
                                border-top-right-radius: 5px !important;
                            }

                            border: 0;
                        }

                        td {
                            padding: 0.5rem 1.5rem;
                            color: cssvar(primary-text);

                            &:last-child {
                                border-left: 2px solid cssvar(cards-and-blocks);
                                position: relative;
                                vertical-align: middle;
                            }

                            &:last-child::before {
                                content: '';
                                background-color: cssvar(border-and-dividers);
                                position: absolute;
                                left: 0;
                                top: 10%;
                                bottom: 10%;
                                width: 0.5px;
                            }
                        }
                    }
                }
            }

            .plan-label {
                width: 20%;
                border-radius: 5px;
                line-height: 10px;
                color: cssvar(cards-and-blocks);
                padding: 0.4rem 1.25rem;
                font-size: 14px;
                font-weight: normal;
                font-family: Arial, sans-serif;
                margin-bottom: 0;
                text-align: center;
            }

            .text-data {
                color: cssvar(primary-text);
                font-family: Arial, sans-serif;
                line-height: 15px;
            }

            .date-data {
                color: cssvar(primary-text);
                font-family: Arial, sans-serif;
                font-size: 10px;
                line-height: 23px;
            }

            .action-data {
                text-align: right;
                padding-right: 0;
            }

            .failed-label {
                background: #f8696b;
            }

            .success-label {
                background: #009eff;
            }
        }
    }
}

.error-msg-popup {
    .modal-dialog {
        max-width: 634px;
    }
}

.charttoggle .dropdown > span {
    padding-right: 5px;
    font-weight: bold;
}

.min-height-320 {
    min-height: 320px;
}
.adjust-chart-position {
    top: -50px;
}
.doughnut-title-position {
    position: absolute;
    width: auto;
    text-align: center;
    left: -4px;
    top: -15px;
}
.no-open-defects {
    color: cssvar(secondary-text-and-icons);
    text-align: center;
    margin-top: 60%;
    font-size: 20px;
    line-height: 27px;
}

.arrow-icon {
    font-size: 18px;
    cursor: pointer;
    color: cssvar(secondary-text-and-icons);
}
.stop-jumping {
    min-height: 407px;
    min-width: 654px;
}

@include media-breakpoint-up(md) {
    .template-grid {
        .charttoggle,
        .normalchart,
        .multi-chart,
        .drilldown-chart,
        .custom-chart {
            width: 50%;
        }
    }
}
